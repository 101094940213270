@font-face {
  font-family: sf_pro_display_bold;
  src: url('fonts/SF-Pro-Display-Bold.otf');
}

@font-face {
  font-family: sf_pro_display_semi_bold;
  src: url('fonts/SF-Pro-Display-Semibold.otf');
}

@font-face {
  font-family: sf_pro_display_medium;
  src: url('fonts/SF-Pro-Display-Medium.otf');
}

@font-face {
  font-family: sf_pro_display_regular;
  src: url('fonts/SF-Pro-Display-Regular.otf');
}

:root {
  --grey: #9F9FA2;
  --grey-darken: #8D8C92;
  --red: #c41624;
  --purple: #9C5EFF;
  --black: #111111;
  --blue: #0091FF;
  --green: #55ce00;
}

/* *******************************
    Generic Styles
********************************* */

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.heading {
  color: var(--black);
  font-family: 'sf_pro_display_bold';
  font-size: 32px
}

.title {
  color: var(--black);
  font-family: 'sf_pro_display_bold';
  font-size: 22px;
}

.grey_regular_18 {
  font-family: 'sf_pro_display_regular';
  font-size: 18px;
  color: var(--grey);
}

.grey_regular_16 {
  font-family: 'sf_pro_display_medium';
  font-size: 14px;
  color: var(--grey-darken);
}
.grey_regular_12 {
  font-family: 'sf_pro_display_regular';
  font-size: 12px;
  color: var(--grey);
}

.grey_bold_18 {
  font-family: 'sf_pro_display_bold';
  font-size: 18px;
  color: var(--grey-darken);
}

.grey_medium_20 {
  font-family: 'sf_pro_display_medium';
  font-size: 20px;
  color: var(--grey);
}

.purple_bold_18 {
  color: var(--purple);
  font-family: 'sf_pro_display_bold';
  font-size:18px;
}

.bold_18 {
  font-family: 'sf_pro_display_bold';
  font-size: 18px;
}

.row-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row {
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  max-width: 800px;
}
.mblcontainer {
  max-width: 400px;
}
/* *******************************
    Index.html Styles
********************************* */

.linkedin_link, .whatsapp_link, .facebook_link, .email_link {
  text-decoration: none;
  color: var(--black);
}

.v_card {
  border: none;
  border-radius: 20px;
  box-shadow:-2px 10px 40px #ccc;
  background-color: white;
}
.vcard_display{
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.container_back {
  background-image: linear-gradient(#f4f4f4, white);
  width: '100%';
  justify-content: center;
  display: flex;
}
.container_back2 {
  width: '100%';
  justify-content: center;
  display: flex;
}

.accurate_link {
  display: block;
  color: #9F9FA2;
  font-family: 'sf_pro_display_regular';
  font-size: 18px;
  line-height: 16px;
}

.accurate_link:hover {
  color: #6d6d6d;
  text-decoration: underline;
}

.personal_detail {
  background: -webkit-linear-gradient(left, #9C5EFF, #0091FF);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-family: 'sf_pro_display_bold';
  font-size: 32px;
}

.mapshow {
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  height:331px;
}

.verification_title {
  font-family: 'sf_pro_display_bold';
  font-size: 20px;
  text-align: left;
  margin-left: 20px;
}
.verification_title2 {
  font-family: 'sf_pro_display_bold';
  font-size: 20px;
  text-align: left;
}

#dummy_map {
  background: url('images/map-placeholder.png');
}

.location_m_icon {
  width: 110px;
  margin: auto;
}

.profile_image {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  /* float: right; */
  background-size: cover;
  margin-top: 5px;
}
.locationIcon {
  height: 12px;
  width: 12px;
  /* float: right; */
  background-size: cover;
  margin-right: 2px;
}
.appIcons {
  height: 24px;
  width: 24px;
  /* float: right; */
  background-size: cover;
  margin-right: 6px;
}

.px-3{
  padding-left: 3px;
  padding-right: 3px;
}
.px-1{
  padding-left: 1px;
  padding-right: 1px;
}

#geeks {
  height: 100vh;
  width: 100vw;
  font-size: 20px;
  margin: 0px;
  justify-content: center;
  align-items: center;
}

.btn{
  width: 100%;
  background: -webkit-linear-gradient(left, #9C5EFF, #0091FF);
  height: 40px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}